import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default () => {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.recaptcha_key,
    loaderOptions: {
      useRecaptchaNet: true
    }
  })
}
